import React from 'react';
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles'

import Layout from 'components/layout';
import SEO from 'components/seo';
import Contents from 'components/contents';
import Paragraphs from 'components/paragraph';
import Heading from 'components/heading';
import HeroBox from 'components/herobox';
import LinkButton from 'components/linkbutton';

import GalleryStation from 'components/gallery/otsuki/stationZhHant'
import GalleryLinear from 'components/gallery/otsuki/linearZhHant'
import GalleryMichinoeki from 'components/gallery/otsuki/michinoekiZhHant'
import GallerySaruhashi from 'components/gallery/otsuki/saruhashiZhHant'

const useStyles = makeStyles(theme => ({
  root: {

  },
  heroImageSection: {
        padding: '0px',
        margin: '0px',
    },
    rootContainer: {
        padding: 0,
    },
    pageDescriptions: {
      padding: '30px',
    },
    contentRoot: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.6)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    id: {
      marginTop: '5rem',
    },
    imageContainer: {

    },
}));

const IndexPage = ({data}) => {
  const classes = useStyles();
  const language = 'zh_hant';
  const slug = '';
  const mfgtTitle = {
    ja: '富士山ゴールデントライアングル',
    en: 'The Mt. Fuji Golden Triangle',
    zh_hant: '富士黃金三角',
    zh_hans: '富士黄金三角'
  };
  const pageDescs = {
    ja: [
      '日本の田舎町ぶらり旅',
      'おすすめルート',
      '日本一の富士を巡り、ワインと温泉と旬の果物を楽しむ',
      '富士山、大月駅、石和温泉駅を結ぶ三角ルート。',
      'たのしい、おいしい、わくわくがいっぱい。',
      '名付けて、富士山ゴールデントライアングル。'
    ],
    en: [
      'Japan Rural Roaming',
      'Recommended Route',
      'Visit world famous Mt. Fuji and enjoy wine, hot springs, and seasonal fruits',
      'The triangle route connects Mt. Fuji, Otsuki Station, and Isawa-Onsen Station.',
      'Full of fun, delicious, and exciting experiences.',
      'The Mt. Fuji Golden Triangle.'
    ],
    zh_hant: [
      '日本田園之旅',
      '推薦行程',
      '徜徉富士山色湖光、盡享溫泉美酒鮮果',
      '富士山、大月站與石和溫泉站，',
      '剛好形成一個有趣的三角路線，',
      '我們叫它「富士黃金三角」。'
    ],
    zh_hans: [
      '日本田园之旅',
      '推荐行程',
      '徜徉富士山色湖光、尽享温泉美酒鲜果',
      '富士山、大月站与石和温泉站，',
      '我们叫它「富士黄金三角」。'
    ]
  };
  const notChild = false;
  const child = true;
  const otsuki = data.stationsJson.pages[language];
  const isawaonsen = data.railroadJson.pages[0][language];
  return (
    <Layout language={language} slug={slug}>
      <SEO title={mfgtTitle[language]} />
      <section className={classes.heroImageSection}>
          <Img objectFit="contain" fluid={data.mfgt.childImageSharp.fluid} />
      </section>
      <section>
        <div className={classes.pageDescriptions}>
          <p>從東京前往富士山時，<br />一般都從新宿經由大月前往，<br />回程也是一樣經由大月回到新宿。</p>
          <p>難得一趟旅行，走一樣的路回去不是很可惜嗎？？<br />Jruro強力推薦！你可以在去程或回程時，<br />經由「石和溫泉」搭乘巴士來個黃金三角移動！</p>
          <ul>
            <li>新宿&rArr;大月&rArr;富士山&rArr;石和溫泉&rArr;新宿</li>
            <li>新宿&rArr;石和溫泉&rArr;富士山&rArr;大月&rArr;新宿</li>
          </ul>
          <p>石和溫泉還盛產葡萄酒和水果，<br />有很多富士山沒有的享受！</p>
          <p>同時擁抱富士山與石和溫泉的三角移動──<br />富士黃金三角，Let’s GO！</p>
          {/* {pageDescs[language].map((description, i) => (
            <p key={i}>{description}</p>
          ))} */}
          {/* <LinkButton url="isawaonsen" title={isawaonsen.title} type="anchor" />
          <LinkButton url="otsuki" title={otsuki.stationName} type="anchor" /> */}
        </div>
      </section>
      <Container component="section" className={classes.rootContainer}>
        <div id="isawaonsen" className={classes.id}></div>
        <HeroBox
          image={data.isawaonsen.childImageSharp.fluid}
          stationName={isawaonsen.title}
          descriptions={isawaonsen.descriptions}
          language={language}
        />
        <Box component="article">{
          isawaonsen.sections.map((section, i) => {
            if (!section.isChild) {
                if ('article' === section.type) {
                    return (
                      <section key={i}>
                          <Heading title={section.title} isChild={section.isChild} />
                          <Contents language={language} contents={section.contents} />
                      </section>
                            )
                } else if ('link' === section.type) {

                } else if ('gallery' === section.type) {

                } else {

                }
            } else {

            }
          })
        }</Box>
        <Box component="article">
          <Heading title="武田二十四將旅館" isChild={notChild} />
          <div className={classes.imageContainer}><Link to="/zh_hant/station/isawaonsen/takeda-24-generals"><Img objectFit="contain" fluid={data.takeda24.childImageSharp.fluid} /></Link></div>
        </Box>
        {/* <div id="otsuki" className={classes.id}></div>
        <HeroBox
          image={data.otsuki.childImageSharp.fluid}
          stationName={otsuki.stationName}
          descriptions={otsuki.descriptions}
          language={language}
        />
        <Box component="article">{
          otsuki.sections.map((section, i) => {
            if (!section.isChild) {
                if ('article' === section.type) {
                    if (section.link) {
                        return (
                            <section key={i}>
                                <Heading title={section.title} isChild={section.isChild} />
                                <Paragraphs paragraphs={section.paragraphs} />
                                <div className={classes.button}>
                                    <LinkButton url={section.link} title={section.title} type="external" />
                                </div>
                            </section>
                        )
                    } else {
                        return (
                            <section key={i}>
                                <Heading title={section.title} isChild={section.isChild} />
                                <Paragraphs paragraphs={section.paragraphs} />
                            </section>
                        )
                    }
                } else if ('gallery' === section.type) {
                    if ('stations/otsuki/station' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryStation idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/linear' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryLinear idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/michinoeki' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryMichinoeki idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/saruhashi' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GallerySaruhashi idxSection={i} language={language} />
                            </section>
                        )
                    } else {

                    }
                } else if ('link' === section.type) {

                } else {

                }
            } else {
                if ('article' === section.type) {
                    if (section.link) {
                        return (
                            <section key={i}>
                                <Heading title={section.title} isChild={section.isChild} />
                                <Paragraphs paragraphs={section.paragraphs} />
                                <div className={classes.button}>
                                    <LinkButton url={section.link} title={section.title} type="external" />
                                </div>
                            </section>
                        )
                    } else {
                        return (
                            <section key={i}>
                                <Heading title={section.title} isChild={section.isChild} />
                                <Paragraphs paragraphs={section.paragraphs} />
                            </section>
                        )
                    }
                } else if ('gallery' === section.type) {
                    if ('stations/otsuki/station' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryStation idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/linear' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryLinear idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/michinoeki' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GalleryMichinoeki idxSection={i} language={language} />
                            </section>
                        )
                    } else if ('stations/otsuki/saruhashi' === section.gallery.directory) {
                        return (
                            <section className={classes.galleryContainer} key={i}>
                                <GallerySaruhashi idxSection={i} language={language} />
                            </section>
                        )
                    } else {

                    }
                } else if ('link' === section.type) {
                    
                } else {

                }
            }
          })
        }</Box> */}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  {
    mfgt:file(relativePath: {eq: "campaign/mfgt/zh.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    takeda24:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stationsJson(name: {eq: "otsuki"}) {
      id
      name
      lat
      lon
      route_id
      prefecture_id
      zip
      address
      heroImage
      pages {
        ja {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        en {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        zh_hant {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
        zh_hans {
          descriptions
          language
          stationName
          sections {
            isChild
            link
            paragraphs
            title
            type
            gallery {
              directory
              items {
                  fileName
                  title
              }
            }
          }
        }
      }
    }
    otsuki:file(relativePath: {eq: "stations/otsuki/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
    }
    railroadJson(name: {eq: "isawaonsen"}) {
      id
      heroImage
      address {
        en {
          address1
          address2
          city
          language
          prefecture
        }
        ja {
          address1
          address2
          city
          language
          prefecture
        }
        zh_hans {
          address1
          address2
          city
          prefecture
          language
        }
        zh_hant {
          address1
          address2
          city
          language
          prefecture
        }
      }
      name
      lon
      lat
      zip
      stationName {
        en
        ja
        zh_hans
        zh_hant
      }
      route_id
      prefecture_name
      prefecture_id
      pages {
        path
        slug
        fileName
        ja {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            link
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        en {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        zh_hant {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
        zh_hans {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
      }
    }
    isawaonsen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/hero.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage;